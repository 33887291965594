<template>
  <div class="d-flex job-filter-partner">
    <div
      class="card job-tab mr-2 p-1 px-2 text-center"
      v-bind:class="{ activeTab: status == 1 }"
      @click="changeStatus(1)"
    >
      <div style="color: #fbc637">
        <i style="font-size: 34px" class="ri-time-line"></i>
      </div>

      <strong> Scheduled Jobs</strong>
    </div>
    <div
      class="card job-tab p-1 px-2 mr-2 text-center"
      v-bind:class="{ activeTab: status == 2 }"
      @click="changeStatus(2)"
    >
      <img
        style="margin: auto"
        height="28"
        width="32"
        :src="require('@/assets/images/partner/Icon awesome-car-alt.png')"
        alt=""
        srcset=""
      />
      <strong> Ongoing Jobs</strong>
    </div>
    <div
      class="card job-tab p-1 px-2 text-center"
      v-bind:class="{ activeTab: status == 3 }"
      @click="changeStatus(3)"
    >
      <img
        style="margin: auto"
        height="28"
        width="32"
        :src="require('@/assets/images/partner/Icon material-history.png')"
        alt=""
        srcset=""
      />
      <strong> Completed Jobs</strong>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: this.$route.params.id||1,
    };
  },
  methods: {
    changeStatus(status) {
      this.$emit("changeStatus", status);
      this.status = status;
    },
  },
};
</script>

<style>
.job-filter-partner .card:hover {
  box-shadow: 0px 0px 12px 0px rgb(209 199 199 / 75%);
  -webkit-box-shadow: 0px 0px 12px 0px rgb(209 199 199 / 75%);
  -moz-box-shadow: 0px 0px 12px 0px rgb(209 199 199 / 75%);
  cursor: pointer;
}
.activeTab {
  opacity: 1 !important;
}
.job-tab {
  opacity: 0.6;
}
</style>
