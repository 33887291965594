export const job = {
  get: {
    url: "inspection/getJobs",
    method: "GET",
    params: null,
  },
  getDetails: {
    url: "inspection/getJobDetails/",
    method: "GET",
    id: null,
  },
  startWrapping: {
    url: "inspection/startWrapping",
    method: "POST",
    data: null,
  },
  uploadCarImage: {
    url: "inspection/uploadCarImage",
    method: "POST",
    data: null,
  },
  inspectCarV2: {
    url: "inspection/inspectCarV2",
    method: "POST",
    data: null,
  },
  finishWork: {
    url: "inspection/finishWorkV2",
    method: "POST",
    data: null,
  },
  loadWorkTime: {
    url: "workstation/getTimeSlot/",
    method: "GET",
    id: null,
  },
  updateTime: {
    url: "workstation/updateTime",
    method: "POST",
    data: null,
  },
  searchForDriver: {
    url: "inspection/SearchDriver",
    method: "GET",
    params: null,
  },
};
